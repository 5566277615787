import router from "@/router";
import store from "@/store";

export const basicMixin = {
  data() {
    return {
      msg: "我是mixin中的数据",
      mmm: "使用minxin中的属性",
    };
  },
  methods: {
    // 查询这条子级数据的父级数据
    findParentTitle(v:any, list:any){
      let data;
      (list || []).map((i:any) => {
        if (i.id == v) {
          data = [{
            name:i.name,
            clickUrl:i.clickUrl
          }];
        } else {
          const child = basicMixin.methods.findParentTitle(v, i.children);
          if (child) {
            data = [{
              name:i.name,
              clickUrl:i.clickUrl
            }, ...child];
          }
        }
      });
      return data;
    },
    // 跳转路径
    toPath(path:string){
      router.push(path)
    },
    // 通过锚点跳转至指定模块处
    toModuleLocation() {
      // 地址锚点
      let hash = window.location.hash
      let index = hash.lastIndexOf('#')
      if (index != -1) {
        // 锚点去除警号
        let id = hash.substring(index + 1, hash.length + 1)
        // 获取与锚点名相同的id
        let div = document.getElementById(id)
        if (div) {
          // 定位到指定位置
          setTimeout(()=>{
            // document.documentElement.scrollTo(0,div?.offsetTop || 0)
            div?.scrollIntoView({  behavior: 'smooth',block:'start' })
          },30)
         
        }
      }
    },
    //给对象相同属性赋值
    listAssign(arrA: any, arrB: any) {
      Object.keys(arrA).forEach((key) => {
        arrA[key] = arrB[key] || arrA[key];
      });
    },
    //给对象相同属性赋值---有零的情况
    listAssignZero(arrA: any, arrB: any) {
      Object.keys(arrA).forEach((key) => {
        if (arrB[key] == undefined) return;
        // debugger
        // console.log("arrB[key]",arrB[key]);
        arrA[key] = arrB[key] || arrA[key];
        // arrA[key] = arrB[key].toFixed(2);
        if (arrB[key] === "0.00") {
          arrA[key] = "0";
        }
      });
    },
    // 深拷贝
    deepClone(obj: any) {
      return JSON.parse(JSON.stringify(obj));
    },
    useBase64ByURL(imgUrl: string){
      return new Promise((resolve) => {
        window.URL = window.URL || window.webkitURL;
        var xhr = new XMLHttpRequest();
        xhr.open("get", imgUrl, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (this.status == 200) {
            var blob = this.response;
            let oFileReader = new FileReader();
            oFileReader.onloadend = function (e: any) {
              resolve({ blob, base64: e.target.result });
            };
            oFileReader.readAsDataURL(blob);
          }
        };
        xhr.send();
      });
    }
  },
};
function find(v: any, children: any) {
  throw new Error("Function not implemented.");
}

